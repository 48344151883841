<template>
  <div class="maxHeight">
    <div class="header">
      <div class="title flex">
        <img src="../../assets/img/user.png" alt="" width="28">
        <h2>用户管理</h2>
      </div>
    </div>
    <div class="contentBody">
      <div class="flex tableHead">
        <el-button size="small" @click="add">新增用户</el-button>
        <el-input clearable v-model="search" placeholder="请输入名称/手机号码" suffix-icon="el-icon-search"
                  @change="skipPage(1)"></el-input>
      </div>
      <el-table
          border :default-sort="{prop: 'date', order: 'descending'}"
          :data="tableData"
          class="record-table"
          :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
          height="calc(100vh - 380px)">
        <el-table-column
            sortable label="用户名称" width="120">
          <template slot-scope="scope">
            <span v-text="scope.row.username.length>0?scope.row.username.split('.')[1]:''"></span>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" sortable label="昵称">
        </el-table-column>
        <el-table-column label="性别" width="80">
          <template slot-scope="scope">
            <span v-text="scope.row.ssex=='0'?'男':scope.row.ssex=='1'?'女':'保密'"></span>
          </template>
        </el-table-column>
        <el-table-column prop="deptName" sortable label="部门">
        </el-table-column>
        <el-table-column prop="roleNames" label="角色" sortable show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号码" width="120">
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <el-tag v-text="scope.row.status==1?'有效':'锁定'"
                    :type="scope.row.status === '1' ? 'primary' : scope.row.status === '0'?'danger':'warning'"
                    disable-transitions>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | formatDay }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="90">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.$index, scope.row)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="handleDelete(scope.row.userId)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex pagination ">
        <el-pagination
            prev-text="<"
            next-text=">"
            @size-change="handleSizeChange"
            @current-change="skipPage"
            :current-page="page.current"
            :page-size="page.size"
            layout="prev, pager, next"
            :total="page.total">
        </el-pagination>
        <span class="total">共计{{ Math.ceil(page.total / page.size) }}页</span>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false"
               :title="action+'用户'"
               class="flex dialogForm "
               top="0"
               :visible.sync="dialogFormVisible"
               width="400px">
      <el-form ref="formAction" size="small" :model="formAction" class="addForm" label-suffix=":" :rules="ruleInline"
               label-width="90px">
        <el-form-item label="用户名" prop="preUsername">
          <el-input v-model="formAction.preUsername" placeholder="请输入用户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="formAction.nickname" placeholder="请输入昵称" clearable></el-input>
        </el-form-item>
        <el-form-item label="密码" :prop="(action=='新增')?'password':''" :style="{'display':baseStyles}">
          <el-input type="password" v-model="formAction.password" placeholder="请输入密码" clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" prop="departIdArr">
          <a-tree-select :dropdownStyle="{ maxHeight: '180px', overflow: 'auto' }" :treeData="deptTreeData"
                         placeholder='请选择部门' treeDefaultExpandAll v-model="formAction.departIdArr">
          </a-tree-select>
        </el-form-item>
        <el-form-item label="角色" prop="roleIdArr">
          <el-select v-model="formAction.roleIdArr" multiple collapse-tags clearable
                     placeholder="请选择角色" popper-class="characterSelect">
            <el-option v-for="item in options" :key="item.roleId" :label="item.name" :value="item.roleId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="mobile">
          <el-input v-model="formAction.mobile" placeholder="请输入手机号码" clearable></el-input>
        </el-form-item>
        <el-form-item label="状态" class="radioGroup" prop="status">
          <el-radio-group v-model="formAction.status">
            <el-radio :label="'1'">有效</el-radio>
            <el-radio :label="'0'">锁定</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="性别" class="radioGroup" prop="ssex">
          <el-radio-group v-model="formAction.ssex">
            <el-radio :label="'0'">男</el-radio>
            <el-radio :label="'1'">女</el-radio>
            <el-radio :label="'2'">保密</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="commit('formAction')">确认</el-button>
          <el-button size="small" type="primary" plain @click="dialogFormVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>

</template>

<script>

import {mapState} from "vuex";

export default {
  name: "user",
  data() {
    return {
      search: '',
      tableData: [],
      page: {
        current: 1,
        size: 10,
        total: 10,
      },
      formAction: {
        preUsername: '',
        password: '',
        nickname: '',
        ssex: '1',
        departIdArr: [],
        roleIdArr: [],
        roles: [],
        mobile: '',
        status: '1',
      },
      action: '新增',
      ruleInline: {
        preUsername: [
          {required: true, message: '用户名不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        departIdArr: [
          {required: true, message: '部门不能为空', trigger: 'blur'},
        ],
        roleIdArr: [
          {required: true, message: '角色不能为空', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '手机号码不能为空', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '状态不能为空', trigger: 'blur'},
        ],
      },
      // 角色
      options: [],
      // 部门
      deptTreeData: [],
      isEdit: false,
      dialogFormVisible: false,
      baseStyles: 'none',
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
  },
  mounted() {
    this.getDataFromServer();
    this.getRoleFromServer();
    this.getDepartFromServer()
  },
  methods: {
    getDataFromServer() {
      this.$get('/oauth/user/personal/page/' + this.user.companyId,
          {
            pageSize: this.page.size,
            pageNum: this.page.current,
            key: this.search
          }).then(res => {
        this.tableData = res.data.records
        this.page.current = res.data.current;
        this.page.total = res.data.total;
        this.page.size = res.data.size;
      })
    },
    //获取角色
    getRoleFromServer() {
      this.$get("/oauth/role/" + this.user.companyId).then(res => {
        this.options = res.data;
      })
    },
    //获取部门树
    getDepartFromServer() {
      this.$get("/oauth/dept/tree").then(res => {
        this.deptTreeData = res.data.children;
      })
    },
    // 每页多少条
    handleSizeChange(val) {
      this.page.size = val;
    },
    // 当前页
    skipPage(current) {
      this.page.current = current;
      this.getDataFromServer();
    },
    //新增
    add() {
      this.baseStyles = '';
      this.isEdit = false;
      this.formAction = {
        preUsername: '',
        password: '',
        ssex: '1',
        departIdArr: [],
        roleIdArr: [],
        mobile: '',
        status: '1',
      };
      this.action = '新增'
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    //   编辑
    handleEdit(index, row) {
      this.baseStyles = 'none';
      this.isEdit = true;
      this.action = '编辑'
      // console.log(row.roleIds.split(","))
      let arr = row.roleIds.split(",")
      let newArr = []
      arr.forEach(item => {
        item = Number(item)
        newArr.push(item)
      })
      this.formAction = {
        userId: row.userId,
        preUsername: row.username.length > 0 ? row.username.split('.')[1] : '',
        password: row.password,
        ssex: row.ssex,
        departIdArr: row.deptId,
        roleIdArr: newArr,
        mobile: row.mobile,
        status: row.status,
        nickname: row.nickname
      }
      // this.roleNames= (row.roleNames!=null&&row.roleNames.length>0)?row.roleNames.split('.'):'';
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    //删除
    handleDelete(id) {
      this.$confirm('此操作将删除该数据，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("/oauth/user/delete", {userId: id}).then(() => {
          this.$message.success("删除成功！");
          this.getDataFromServer();
        }).catch(() => {
          this.$message.info("删除失败！");
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    //提交
    commit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.formAction)
          this.formAction.deptId = this.formAction.departIdArr;/*.join(",")*/
          this.formAction.roleIds = this.formAction.roleIdArr.join(",");
          if (this.formAction.nickname == '') {
            this.formAction.nickname = this.formAction.preUsername
          }
          let url;
          if (!this.isEdit) {
            // console.log(this.formAction)
            url = this.$post('/oauth/user/company', this.formAction)
          } else {
            url = this.$put('/oauth/user', this.formAction)
          }
          url.then(() => {
            this.dialogFormVisible = !this.dialogFormVisible;
            this.$message.success("提交成功")
            this.getDataFromServer();

          }).catch((err) => {
            this.$message.error(err.response.data.message)
          })
        }

      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/exper/experiment";
</style>
